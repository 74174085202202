<template>
  <div class="aboutme">
    <div class="nav">
      <!-- 导航 -->
      <div class="nav-1 w">
        <ul>
          <li>
            <a @click="topClick(2)"
               :class="[isimg==2?'active li':'li']"
               href="javascript:;">{{$t('news.exhibition')}}</a>
          </li>
          <li>
            <a @click="topClick(3)"
               :class="[isimg==3?'active li':'li']"
               href="javascript:;">{{$t('news.videos')}}</a>
          </li>
          <li>
            <a @click="topClick(1)"
               :class="[isimg==1?'active li':'li']"
               href="javascript:;">{{$t('news.pictures')}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap mb35 w clearfix"
         v-loading="loading">
      <div class="wrap-left">
        <ol>
          <li v-for="cate in categoryList"
              :key="cate.id"
              :class="[categoryId == cate.id ? 'activeLi' : '']">
            <a @click="swCate(cate.id)"
               href="javascript:;">
              <span>{{cate.title}}</span>
            </a>
          </li>
        </ol>
      </div>
      <div class="wrap-middle"></div>

      <!-- 展会新闻 -->
      <div class="wrap-right">
        <template v-if="isimg==2">
          <ul v-if="newsList.length">
            <li @click="Img(news.id)"
                v-for="(news, i) in newsList"
                :key="isimg+'_'+i">
              <div class="newsMinImg">
                <img :src="news.image"
                     alt />
              </div>
              <h5>{{news.title}}</h5>
              <p>{{news.create_time}}</p>
              <i class="newsfrom">
                {{$t('news.source')}} :
                <img :src="news.media_logo"
                     alt />
                {{news.media_name}}
              </i>
            </li>
          </ul>
          <empty v-else />
        </template>
        <template v-if="isimg==1">
          <ul v-if="newsList.length">
            <li class="liInmg"
                v-for="(news, i) in newsList"
                :key="isimg+'_'+i"
                style="margin-bottom:40px;">
              <div class="newsMinImg">
                <el-image :src="news.image"
                          :preview-src-list="newsListImg"></el-image>
              </div>
              <!-- <el-image :src="news.image" :preview-src-list="[]"></el-image> -->
              <!-- <h5>{{news.title}}</h5>
              <p>{{news.create_time}}</p>-->
            </li>
          </ul>
          <empty v-else />
        </template>
        <template v-if="isimg == 3">
          <ul v-if="newsList.length">
            <li class="liInmg"
                v-for="(news, i) in newsList"
                :key="isimg+'_'+i"
                @click="showVideo(news)">
              <div class="newsMinImg">
                <img :src="news.image"
                     alt />
                <div class="kuai-wrap">
                  <img :src="require('@/assets/images/video.png')"
                       alt />
                </div>
              </div>
              <h5>{{news.title}}</h5>
              <p>{{news.create_time}}</p>
              <!-- <i class="newsfrom">
              来源 :
              <img :src="news.media_logo" alt />{{news.media_name}}
              </i>-->
            </li>
          </ul>
          <empty v-else />
        </template>

        <!-- 分页 -->
        <el-pagination v-if="total!==0"
                       background
                       class="pageAction"
                       layout="prev, pager, next"
                       @current-change="handleCurrentChange"
                       :page-size="pageSize"
                       :current-page="indexPage"
                       :total="total"></el-pagination>
      </div>
    </div>
    <!--  底部-->
    <!-- <Footer /> -->
    <videos :dialogVisible="startVideo"
            :videos="srcVideo"
            @onstart="onstartVideo"
            :title="titleModel"></videos>
  </div>
</template>

<script>
import { getIndexData } from "@/api/index";
import videos from "@/components/videos";
import empty from '@/components/emptyBox/index'
export default {
  name: "Stream",
  components: {
    videos,
    empty
  },
  data () {
    return {
      paginations: {
        total: 100
      },
      videoHandle: {
        dialogVisible: true,
        url: "",
        title: ""
      },
      loading: true,
      isimg: "1",
      swiper: "",
      // url:
      //   "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
      ],
      categoryList: [],
      newsListImg: [],
      newsList: [],
      page: 1,
      lang: "zh-cn",
      categoryId: 0, //默认全部
      type: 2, //默认展会新闻
      total: 0,
      indexPage: 1,
      pageSize: 9,
      startVideo: false,
      srcVideo: "",
      titleModel: "详细视频"
    };
  },

  watch: {
    $route: {
      handler () {
        // this.$router.go(0);
        if (this.$route.name == 'New') {
          const type = this.$route.query.type;
          this.topClick(type);
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    //获取路由后面的参数
    (this.isimg = this.$route.query.item || 1),
      console.log(this.$route.query.item);

    this.swiper = new Swiper(".swiper-container", {
      loop: true,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      autoplay: 5000,
      speed: 700,
      //用户操作之后是否停止自动轮播默认true
      autoplayDisableOnInteraction: false
    });
    this.getNewsList(this.page, this.type, this.categoryId, this.lang);
  },
  created () {
    if (this.$route.query.start) {
      if (this.$route.query.start == 1) {
        this.categoryId = 24;
      } else if (this.$route.query.start == 2) {
        this.categoryId = 25;
      } else if (this.$route.query.start == 3) {
        this.categoryId = 26;
      }
    }
  },
  methods: {
    onstartVideo () {
      this.startVideo = false;
    },
    handleCurrentChange (val) {
      this.indexPage = val;
      this.getNewsList();
    },
    showVideo (data) {
      this.startVideo = true;
      this.srcVideo = data.video_url;
      this.titleModel = data.title;
    },
    onVideoStart () {
      this.videoHandle.dialogVisible = false;
    },
    topClick (type) {
      this.indexPage = 1;
      this.isimg = type;
      this.type = type;
      this.categoryId = "0";
      this.loading = true;
      this.getNewsList(this.page, type, this.categoryId, this.lang);
    },
    Img (id) {
      this.$router.push("/newDetails?id=" + id);
    },
    swCate (categoryId) {
      this.categoryId = categoryId;
      this.indexPage = 1;
      const changeNav = false;
      console.log(this.categoryId, this.swCate, 6666666)
      this.getNewsList(this.page, this.type, categoryId, this.lang, changeNav);
    },
    getNewsList (page, type, categoryId, lang, changeNav = true) {
      getIndexData({
        page: this.indexPage,
        type: this.type,
        categoryId: this.categoryId,
      }).then(res => {
        this.loading = false;
        if (res.code == "200") {
          if (changeNav) {
            this.categoryList = res.data.categoryList;
          }
          this.newsList = res.data.newsList.list;
          res.data.newsList.list.map(arr => {
            this.newsListImg.push(arr.image)
          })
          this.total = res.data.newsList.total;
          this.isimg = this.type;
        }
      });
    }
  }
};
</script>

<style scoped  lang="scss">
.pageAction {
  ::v-deep {
    .active {
      background-color: #0e9b32 !important;
    }
    li {
      &:hover {
        color: #27ea58 !important;
      }
    }
  }
}
.nav {
  height: 100px;
  background: #f5f5f5;

  .nav-1 {
    height: 100px;

    ul li {
      float: left;
      position: relative;
      .li {
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-size: 26px;
        color: #4c4c4c;
        height: 100px;
        line-height: 100px;
        margin-right: 80px;
        font-weight: 400;
      }
      .activeLi {
        background: #0ba539;
        color: white;
      }
      .active {
        color: #0ba539;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #0ba539;
          display: inline-block;
        }
      }
    }
  }
}
.wrap {
  margin-top: 61px;
  // height: 1229px;
  .newsMinImg {
    width: 284px;
    height: 180px;
    overflow: hidden;
    position: relative;
    .kuai-wrap {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s;
      &:hover {
        opacity: 1;
      }
      img {
        width: 40px;
        height: 40px;
        min-height: auto;
      }
    }
    img,
    .el-image {
      transition: 300ms;
      width: 100%;
      min-height: 100%;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  .newsfrom {
    width: 100%;
    display: flex;
    align-items: flex-end;
    cursor: default;
    position: absolute;
    bottom: 0;
    img {
      height: 17px;
      margin: 0 3px 0 6px;
      border-radius: 50%;
      object-fit: contain;
      position: relative;
      top: 0.5px;
    }
  }
  .wrap-left {
    float: left;
    width: 284px;
    border: 1px balck solid;

    ol {
      width: 280px;
      border: 1px solid #999999;
    }

    ol li {
      width: 280px;
      height: 60px;

      a {
        display: block;
        padding: 0 17px;
        text-decoration: none;
        height: 60px;
        line-height: 60px;
        color: #222;
        font-size: 22px;

        span {
          display: block;
          padding: 0 23px;
          border-bottom: 1px #999999 dashed;
        }

        &:hover {
          color: #fff;
          background: #0e9b32;
        }
      }

      &:last-child {
        a {
          span {
            border-bottom: none;
          }
        }
      }
    }

    li {
      &.activeLi {
        background: #0e9b32;
        a {
          color: #fff;
        }
      }
    }
  }

  .wrap-middle {
    float: left;
    width: 18px;
    // height: 1229px;
    // background: lightgoldenrodyellow;
  }

  .wrap-right {
    float: right;
    width: 900px;
    position: relative;
    text-align: left;
    // height:1229px;
    // background: lightgreen;
    h5 {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin-top: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      height: 45px;
      overflow: hidden;
    }
    p {
      padding-top: 10px;
    }
  }
  ul {
    overflow: hidden;
    li {
      float: left;
      height: 318px;
      width: 285px;
      // background: lightpink;
      margin-right: 20px;
      margin-bottom: 59px;
      position: relative;
      cursor: pointer;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }

      h5 {
        color: #333;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-top: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 45px;
      }
      p {
        color: #999;
        font-size: 12px;
        margin-top: 5px;
      }
      i {
        display: block;
        color: #999999;
        font-size: 16px;
        margin-top: 36px;

        img {
        }
      }
    }
    .liInmg {
      height: 215px !important;
      object-fit: call;
      text-align: left;
      h5 {
        height: 45px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
      }
      p {
        padding-top: 0;
      }
    }
  }
}

.mb35 {
  margin-bottom: 35px;
}

::v-deep .el-pagination {
  text-align: center;
}
</style>
